import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [source, setSource] = useState('fareharbor'); // Default source
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filter, setFilter] = useState('');

  // ✅ Fetch data dynamically based on dropdown selection
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://dev.riostack.com/api/${source}`);
        setData(response.data);
      } catch (error) {
        console.error(`❌ Error fetching ${source} data:`, error);
      }
    };

    fetchData();
  }, [source]);

  // ✅ Sorting logic
  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const order = sortConfig.direction === 'asc' ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) return -order;
    if (a[sortConfig.key] > b[sortConfig.key]) return order;
    return 0;
  });

  // ✅ Filtering logic
  const filteredData = sortedData.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(filter.toLowerCase())
    )
  );

  // ✅ Handle sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // ✅ Group by conversion_time and count unique order IDs
  const chartData = Object.values(
    filteredData.reduce((acc, item) => {
      const date = item.conversion_time;
      if (!acc[date]) {
        acc[date] = { conversion_time: date, order_count: 0 };
      }
      acc[date].order_count += 1;
      return acc;
    }, {})
  ).sort((a, b) => new Date(a.conversion_time) - new Date(b.conversion_time));

  return (
    <div>
      <h2>RioStack Data Viewer</h2>

      {/* ✅ Dropdown to select the data source */}
      <select value={source} onChange={(e) => setSource(e.target.value)}>
        <option value="fareharbor">FareHarbor Data</option>
        <option value="sticky-data">Sticky Data</option>
      </select>

      {/* ✅ Filter input */}
      <input
        type="text"
        placeholder="Filter data..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ marginLeft: '10px' }}
      />

      {/* ✅ Line Chart (Unique Orders Per Day) */}
      {source === 'fareharbor' && chartData.length > 0 && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="conversion_time" tick={{ fontSize: 12 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="order_count" stroke="#8884d8" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      )}

      {/* ✅ Dynamic Table */}
      <table border="1" style={{ marginTop: '20px', width: '100%' }}>
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key) => (
                <th key={key} onClick={() => handleSort(key)}>
                  {key} {sortConfig.key === key ? (sortConfig.direction === 'asc' ? '⬆️' : '⬇️') : ''}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              {Object.keys(row).map((key) => (
                <td key={key}>{row[key] ?? '—'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;