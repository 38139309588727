import React from 'react';
import DataTable from './DataTable';

function App() {
  return (
    <div>
      <h1>RioStack Data Explorer</h1>
      <DataTable />
    </div>
  );
}

export default App;